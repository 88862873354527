$(document).on('turbo:load', () => {
  const sidebar = $('#sidebar');

  if(localStorage.getItem("statusSidebar") == "expanded") {
    sidebar.removeClass("collapsed"); 
  }else if(localStorage.getItem("statusSidebar") == "collapsed"){
    sidebar.addClass("collapsed"); 
  }

  $('#menu-burger').on('click', () => {
    sidebar.toggleClass("collapsed");
    let status = sidebar.hasClass('collapsed') ? 'collapsed' : 'expanded';
    localStorage.setItem('statusSidebar', status);

    sidebar.on("transitionend", () => {
      window.dispatchEvent(new Event("resize"));
    });
  });
});